import { api } from "@features/redux/api"
import {InspectionState} from "@appTypes/inspection";

const statesApi = api.injectEndpoints({
    endpoints: builder => ({
        statesList: builder.query<InspectionState[], void>({
            query: () => ({
                url: "/companies/states",
                method: "GET"
            })
        })
    })
})

export const {
    useStatesListQuery
} = statesApi
