import {FunctionComponent, useState, MouseEvent} from "react"

import styles from "./styles.module.css"
import {pick} from "@helpers/object";
import {DataTable} from "@components/admin/DataTable/DataTable";
import {ListPagination} from "@components/common/paginators/ListPagination/ListPagination";
import {useAdminActiveInspectionsQuery} from "@features/inspection/api";
import {Inspection} from "@appTypes/inspection";
import {useNavigate} from "react-router-dom";
import {InspectionProgressLabel} from "@components/common/InspectionProgressLabel/InspectionProgressLabel";
import {getEuropeFormattedDateTimeString} from "@helpers/date";

const displayingFields = ['id', 'status', 'user', 'license_plate_number', 'date_modified', 'processed_by']

export const Requests: FunctionComponent = () => {
    const navigate = useNavigate()
    const [currentPage, setPage] = useState<number>(0)
    const [rowsLimit, setRowsLimit] = useState<number>(10)

    const {data: inspectionsList, isLoading} = useAdminActiveInspectionsQuery({offset: currentPage * rowsLimit, limit: rowsLimit},
        {
            refetchOnMountOrArgChange: true
        })

    if (isLoading) {
        return null
    }

    const inspections = inspectionsList?.list?.map((inspection) => {
        const fields = pick(inspection, displayingFields) as Inspection
        return {
            id: fields.id,
            status: <InspectionProgressLabel status={fields.status}/>,
            date_modified: getEuropeFormattedDateTimeString(fields.date_modified),
            user: `${fields.user.first_name} ${fields.user.last_name}`,
            license_plate_number: fields.license_plate_number,
            processed_by: fields.processed_by ? `${fields.processed_by?.first_name} ${fields.processed_by?.last_name}` : "-"
        }
    }) || []
    const count = inspectionsList?.count ? inspectionsList?.count : inspections?.length

    const onRowClick = (event: MouseEvent<HTMLDivElement>, inspection: Inspection) => {
        event.preventDefault()
        event.stopPropagation()

        navigate(`/requests/${inspection.id}`)
    }

    return (
        <div className={styles.page}>
            <DataTable
                headings={['ID', 'Status', 'Date', 'User', 'License Plate №', 'Handler']}
                data={inspections}
                onRowClick={onRowClick}
                classNames={{
                    tableClassName: styles.table,
                    tableHeaderClassName: styles.tableHeader,
                    rowClassName: styles.row,
                    cellClassName: styles.cell,
                    headingClassName: styles.heading,
                    bodyClassName: styles.body
                }}
            />
            <ListPagination count={count} limit={rowsLimit} setLimit={setRowsLimit} currentPage={currentPage}
                            setCurrentPage={setPage}/>
        </div>
    )
}