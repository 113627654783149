import {api} from "@features/redux/api";
import {Company} from "@appTypes/inspection";

export type companiesFilters = {
    id?: number,
    id__in?: number[],
    states__id?: number | null,
    states__id__in?: number[],
    limit?: number,
}

export type companiesListQueryPaginatedResponse = {
    count: number,
    list: Company[],
}

export type companiesListQueryResponse = Company[]

const companiesApi = api.injectEndpoints({
    endpoints: builder => ({
        companiesList: builder.query<companiesListQueryPaginatedResponse & companiesListQueryResponse, void | companiesFilters>({
            query: (args) => ({
                url: "/companies",
                method: "GET",
                params: args,
            })
        })
    })
})

export const {
    useCompaniesListQuery
} = companiesApi
