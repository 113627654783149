import {FunctionComponent, MouseEvent, Suspense, useState} from "react"

import styles from "./styles.module.css"
import {updateUserValidationSchema} from "@helpers/validationSchemas/admin/update-user";
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteSelectInput} from "@components/common/inputs/WhiteSelectInput/WhiteSelectInput";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {BlueRoundedButton} from "@components/common/buttons/BlueRoundedButton/BlueRoundedButton";
import {RedRoundedButton} from "@components/common/buttons/RedRoundedButton/RedRoundedButton";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {UpdateUserPasswordPopup} from "@components/admin/popups/UpdateUserPasswordPopup/UpdateUserPasswordPopup";
import {useParams} from "react-router-dom";
import {
    useBlockUserMutation,
    useUnBlockUserMutation,
    useUpdateUserMutation,
    useUserDetailsQuery
} from "@features/users/api";
import {useAppSelector} from "@features/redux/store";
import {getMe} from "@features/auth/slice";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {User} from "@appTypes/user";
import {useTranslatedSchema} from "@helpers/hooks/useTranslatedSchema";

interface Values extends Pick<User, 'email' | 'phone_number' | 'first_name' | 'last_name'> {
    role: string
}

export const UserDetails: FunctionComponent = () => {
    const {id} = useParams()

    const [updatePasswordPopupOpen, setUpdatePasswordPopupOpen] = useState<boolean>(false)

    const [updateUser] = useUpdateUserMutation()
    const [blockUser] = useBlockUserMutation()
    const [unBlockUser] = useUnBlockUserMutation()

    const me = useAppSelector(getMe)
    const {data: user} = useUserDetailsQuery({id: parseInt(id!)})

    const isUserNotCurrentUser = me?.id !== user?.id

    const schema = useTranslatedSchema(updateUserValidationSchema)

    if (!user) {
        return null
    }

    const initialValues: Values = {
        email: user.email,
        phone_number: user.phone_number,
        first_name: user.first_name,
        last_name: user.last_name,
        role: user.role.name,
    }

    const formUpdateUser = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        try {
            await updateUser({id: user.id, ...values}).unwrap()
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)

                setErrors(errors.errors)
            }
        } finally {
            setSubmitting(false)
        }
    }

    const formBlockUser = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.persist()

        try {
            await blockUser({id: user.id}).unwrap()
        } catch (e: any) {
            console.warn(e)
        }
    }

    const formUnBlockUser = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.persist()

        try {
            await unBlockUser({id: user.id}).unwrap()
        } catch (e: any) {
            console.warn(e)
        }
    }

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={formUpdateUser}
                    validationSchema={schema}>
                {({
                      isSubmitting,
                      isValid,
                  }) => (
                    <Form className={styles.form}>
                        <div className={styles.fields}>
                            <WhiteInput name="email" label="E-mail" disabled/>
                            <WhiteInput name="phone_number" label="Phone number"/>
                            <WhiteInput name="first_name" label="First Name"/>
                            <WhiteInput name="last_name" label="Last Name"/>
                            <WhiteSelectInput label="Role" name="role">
                                <PermittedComponent allowedRoles={["super_admin"]}>
                                    <option value="super_admin" disabled>Super admin</option>
                                    <option value="admin">Admin</option>
                                </PermittedComponent>
                                <option value="inspector">Inspector</option>
                                <option value="user">User</option>
                            </WhiteSelectInput>
                            <div className={styles.updatePasswordButton}>
                                {
                                    user?.auth_provider && user.auth_provider === "django" &&
                                    <BlueRoundedButton onClick={() => setUpdatePasswordPopupOpen(true)}>
                                        Update password
                                    </BlueRoundedButton>
                                }
                            </div>
                        </div>
                        <div className={styles.actions}>
                            {
                                isUserNotCurrentUser &&
                                <>
                                    {
                                        user.is_active ?
                                            <RedRoundedButton onClick={formBlockUser}>Block</RedRoundedButton>
                                            :
                                            <GreenRoundedButton onClick={formUnBlockUser}>UnBlock</GreenRoundedButton>
                                    }
                                </>
                            }
                            <GreenRoundedButton type="submit"
                                                disabled={isSubmitting || !isValid}>Save</GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
            <Suspense fallback="Loading...">
                <UpdateUserPasswordPopup onClose={() => setUpdatePasswordPopupOpen(false)}
                                         isOpened={updatePasswordPopupOpen} user={user}/>
            </Suspense>
        </>
    )
}