import {ChangeEvent, FunctionComponent, createRef, useState, MouseEvent, useId} from "react"

import {useField} from "formik";

import VideoUploadIcon from "@assets/icons/VideoUploadIcon.svg"

import {useTranslation} from "react-i18next";
import styles from "./styles.module.css"
import {InputError} from "@components/common/InputError/InputError";
import {useUploadProgress} from "@helpers/hooks/useUploadProgress";
import {GreenCircularProgress} from "@components/common/progress/GreenCircularProgress/GreenCircularProgress";

interface WhiteCameraVideoInputProps {
    name: string,
    label: string,
    video_format?: string,
    onLabelClickCapture?: (event?: MouseEvent<HTMLElement>) => void
}

export const WhiteCameraVideoInput: FunctionComponent<WhiteCameraVideoInputProps> = ({
                                                                                         name,
                                                                                         label,
                                                                                         video_format = "*"
                                                                                     }) => {
    const id = useId()

    const { t } = useTranslation()

    const {loadingProgress, isLoading, upload} = useUploadProgress()

    const [field, meta, helpers] = useField(name)

    const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0]

        const formData = new FormData()
        formData.append('file', file)

        upload(new URL('inspections/draft/video/', process.env.REACT_APP_BACKEND_API_URL).toString(), formData)
            .then(response => {
                helpers.setValue(response.data)
            })
    }

    return (
        <div className={styles.container}>
            <div className={styles.description}>
                <span className={styles.title}>
                    {label}
                </span>
                <span className={styles.media_type}>
                    &nbsp;{t("common:MediaTypes.Video")}
                </span>
                {!isLoading && <InputError error={meta.error} className={styles.error}/>}
            </div>
            <input {...field} id={id} value="" type="file" onChange={handleUpload} accept={`video/${video_format}`} capture="environment"/>
            <label htmlFor={id} className={styles.inputBox}>
                {
                    field.value?.file &&!isLoading ?
                        <video src={field.value?.file} muted autoPlay controls={false} loop playsInline/>
                        :
                        isLoading ?
                            <div><GreenCircularProgress value={loadingProgress} size="large"/></div>
                            :
                        <div className={styles.placeholder}>
                            <img src={VideoUploadIcon} alt="VideoUploadIcon"/>
                            <p>
                                Upload Video
                            </p>
                        </div>
                }
            </label>
        </div>
    )
}