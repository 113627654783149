import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {
    AccessTime, Instagram,
    MailOutline,
    PhoneSharp, Telegram, WhatsApp,
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const ContactsCard: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.card}>
            <h3>{t("user:ContactUs.card.title")}</h3>

            <div className={styles.items}>
                <div className={styles.item}>
                    <MailOutline/> <Link to="mailto:support@insve.com">support@insve.com</Link>
                </div>
                <div className={styles.item}>
                    <PhoneSharp/> <Link to="tel:(808) 800-92-92">(808) 800-92-92</Link>
                </div>
                <div className={styles.item}>
                    <AccessTime/> <p>09:00 am - 09:00 pm</p>
                </div>
            </div>
            <div className={styles.socials}>
                <p>{t("user:ContactUs.card.socials.heading")}</p>
                <div className={styles.icons}>
                    <WhatsApp/>
                    <Telegram/>
                    <Instagram/>
                </div>
            </div>
        </div>
    )
}