import {FunctionComponent} from "react"

import CircleIcon from "@mui/icons-material/Circle";

import styles from "./styles.module.css"

interface InspectionHintCardProps {
    title: string,
    description: string,
    fileSource: string,
    fileType: 'image' | 'video',
}

export const InspectionHintCard: FunctionComponent<InspectionHintCardProps> = ({title, description, fileSource, fileType}) => {
    let file;

    switch (fileType) {
        case "image":
            file = (<img src={fileSource} alt=""/>)
            break
        case "video":
            file = (<video src={fileSource} loop muted controls controlsList="nodownload" playsInline/>)
            break
    }
    return (
        <div className={styles.hint}>
            <p className={styles.title}>
                {title}
            </p>
            <div className={styles.descriptionContainer}>
                <CircleIcon fontSize="inherit" className={styles.circlePointer}/>
                <p>{description}</p>
            </div>
            <div className={styles.attachment}>
                {file}
            </div>
        </div>
    )
}