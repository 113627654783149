import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Link, useNavigate, useParams} from "react-router-dom";
import {useInspectionDetailsQuery} from "@features/inspection/api";
import {InspectionProgressLabel} from "@components/common/InspectionProgressLabel/InspectionProgressLabel";
import {TabPanel, Tabs, TabsList} from "@mui/base";
import {GreenTab} from "@components/admin/tabs/GreenTab/GreenTab";
import DownloadIcon from "@mui/icons-material/Download";
import {LightBox} from "@helpers/components/LightBox/LightBox";
import {WhiteInfoCard} from "@components/common/WhiteInfoCard/WhiteInfoCard";
import {capitalizeString} from "@helpers/string";
import {useTranslation} from "react-i18next";

export const InspectionDetails: FunctionComponent = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const {data: inspection, isLoading} = useInspectionDetailsQuery({id: parseInt(id!)})

    if (isLoading) {
        return null
    }

    if (!inspection) {
        navigate("/dashboard", {replace: true})
        return null
    }

    return (
        <div className={styles.page}>
            <h3 className={styles.title}>{t("user:InspectionDetails.title")}</h3>
            <div className={styles.content}>
                <div className={styles.infoCard}>
                    <h4>{t("user:InspectionDetails.cards.LicensePlateNumber.title")}</h4>
                    <span>{inspection.license_plate_number}</span>
                </div>
                {
                    inspection.applicant_drivers_license &&
                    <div className={styles.infoCard}>
                        <h4>{t("user:InspectionDetails.cards.ApplicantDriversLicense.title")}</h4>
                        <span>{inspection.applicant_drivers_license}</span>
                    </div>
                }
                <div className={styles.infoCard}>
                    <h4>{t("user:InspectionDetails.cards.InspectionState.title")}</h4>
                    <span>{inspection.inspection_state.name}</span>
                </div>
                <div className={styles.companies}>
                    <h4>{t("user:InspectionDetails.cards.InspectionCompanies.title")}</h4>
                    <div className={styles.grid}>
                        {inspection.inspection_companies.map((company, idx) => (
                            <img src={company.logo} alt={company.name} key={idx}/>
                        ))}
                    </div>
                </div>
                <div className={styles.infoCard}>
                    <h4>{t("user:InspectionDetails.cards.Status.title")}</h4>
                    <InspectionProgressLabel status={inspection.status}/>
                </div>
                {
                    inspection.status === "rejected" && inspection?.rejection_reason &&
                    <WhiteInfoCard label="Rejection reason"
                                   info={inspection.rejection_reason}
                                   className={styles.infoCard}
                    />
                }
                {
                    inspection.status === "rejected" && inspection?.incorrect_fields &&
                    <WhiteInfoCard label="Incorrect Fields"
                                   info={<ul>{inspection.incorrect_fields.split(',').map((field) => <li>{capitalizeString(field.replaceAll('_', ' '))}</li>)}</ul>}
                                   className={styles.infoCard}
                    />
                }
                {
                    inspection.status === "reviewed" && !!inspection?.certificates.length &&
                    <div className={styles.infoCard}>
                        <h4>{t("user:InspectionDetails.cards.Certificates.title")}</h4>
                        <div className={styles.links}>
                            {
                                inspection.certificates.map((certificate, idx) => (
                                    <Link to={certificate.file} target="_blank" className={styles.link} download key={idx}><DownloadIcon/>{certificate.company.name}</Link>
                                ))
                            }
                        </div>
                    </div>
                }
                <div className={styles.files}>
                    <Tabs defaultValue={0} >
                        <TabsList className={styles.tabsList}>
                            <GreenTab value={0}>{t("user:InspectionDetails.files.CarVerificationItems.label")}</GreenTab>
                            <GreenTab value={1}>{t("user:InspectionDetails.files.CarInterior.label")}</GreenTab>
                            <GreenTab value={2}>{t("user:InspectionDetails.files.CarExterior.label")}</GreenTab>
                            <GreenTab value={3}>{t("user:InspectionDetails.files.Tires.label")}</GreenTab>
                        </TabsList>
                        <TabPanel value={0} className={styles.tabPanel}>
                            <div className={styles.filesContainer}>
                                <LightBox>
                                    <img src={inspection.registration_card.file} alt="RegistrationCard"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.odometer.file} alt="Odometer"/>
                                </LightBox>
                            </div>
                        </TabPanel>
                        <TabPanel value={1} className={styles.tabPanel}>
                            <div className={styles.filesContainer}>
                                <video controls playsInline>
                                    <source src={inspection.horn.file}/>
                                </video>
                                <LightBox>
                                    <img src={inspection.interior_driver_side.file} alt="InteriorDriverSide"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.driver_seat_adjustment.file} alt="DriverSeatAdjustment"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.interior_passenger_side.file} alt="InteriorPassengerSide"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.passenger_seat_adjustment.file}
                                     alt="PassengerSeatAdjustment"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.interior_backseat.file} alt="InteriorBackseat"/>
                                </LightBox>
                            </div>
                        </TabPanel>
                        <TabPanel value={2} className={styles.tabPanel}>
                            <div className={styles.filesContainer}>
                                <LightBox>
                                    <img src={inspection.exterior_left.file} alt="ExteriorLeft"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.exterior_right.file} alt="ExteriorRight"/>
                                </LightBox>
                                <video controls playsInline>
                                    <source src={inspection.exterior_front.file}/>
                                </video>
                                <video controls playsInline>
                                    <source src={inspection.exterior_rear.file}/>
                                </video>
                            </div>
                        </TabPanel>
                        <TabPanel value={3} className={styles.tabPanel}>
                            <div className={styles.filesContainer}>
                                <LightBox>
                                    <img src={inspection.left_front_tire.file} alt="LeftFrontTire"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.right_front_tire.file} alt="RightFrontTire"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.left_rear_tire.file} alt="LeftRearTire"/>
                                </LightBox>
                                <LightBox>
                                    <img src={inspection.right_rear_tire.file} alt="RightRearTire"/>
                                </LightBox>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}