import {FunctionComponent, ReactNode, Suspense} from "react"

import styles from "./styles.module.css"
import {Outlet} from "react-router-dom";
import {Skeleton} from "@mui/material";

interface MobileLayoutProps {
    children?: ReactNode | ReactNode[],
}

export const MobileLayout: FunctionComponent<MobileLayoutProps> = ({children}) => {
    return (
        <div className={styles.layout}>
            <div className={styles.container}>
                {children || <Outlet/>}
            </div>
        </div>
    )
}