import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {FormikErrors, useFormikContext} from "formik";
import {InputError} from "@components/common/InputError/InputError";

interface FormErrors extends FormikErrors<unknown>{
    non_field_errors: string[]
}

export const NonFieldErrors: FunctionComponent = () => {
    const {errors} = useFormikContext()

    return (
        <>
            {Array.isArray((errors as FormErrors)?.non_field_errors) ?
                (errors as FormErrors).non_field_errors.map((obj, idx) => (
                    <InputError error={obj as string} key={idx}/>
                ))
                :
                <InputError error={(errors as FormErrors).non_field_errors as unknown as string}/>
            }
        </>
    )
}