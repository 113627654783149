import {api} from "@features/redux/api";
import {Company, Inspection, InspectionStatus} from "@appTypes/inspection";
import {InspectionCertificate} from "@appTypes/files";
import {Optional} from "@helpers/typescript";

type inspectionDetailsQueryArgs = {
    id: number
}
type inspectionDetailsQueryResult = Inspection

export type UserNewInspectionQueryArgs = Pick<Inspection,
    'license_plate_number' |
    'applicant_drivers_license' |
    'inspection_state' |
    'inspection_companies' |
    'registration_card' |
    'odometer' |
    'horn' |
    'interior_driver_side' |
    'driver_seat_adjustment' |
    'interior_passenger_side' |
    'passenger_seat_adjustment' |
    'interior_backseat' |
    'exterior_left' |
    'exterior_right' |
    'exterior_front' |
    'exterior_rear' |
    'left_front_tire' |
    'right_front_tire' |
    'left_rear_tire' |
    'right_rear_tire'
>
export type UserNewInspectionQueryResult = {
    checkout_url: string,
    inspection: Inspection,
}

export type UserReInspectionQueryArgs = Optional<Pick<Inspection,
    'id' |
    'license_plate_number' |
    'applicant_drivers_license' |
    'registration_card' |
    'odometer' |
    'horn' |
    'interior_driver_side' |
    'driver_seat_adjustment' |
    'interior_passenger_side' |
    'passenger_seat_adjustment' |
    'interior_backseat' |
    'exterior_left' |
    'exterior_right' |
    'exterior_front' |
    'exterior_rear' |
    'left_front_tire' |
    'right_front_tire' |
    'left_rear_tire' |
    'right_rear_tire'
>, 'license_plate_number'>
export type UserReInspectionQueryResult = {
    inspection: Inspection,
}

export type InspectionsListFilters = {
    ordering?: "date_modified" | "-date_modified" | "date_created" | "-date_created" | "id" | "-id",
    status?: InspectionStatus,
    status__in: InspectionStatus[]
}
type InspectionCheckoutQueryResult = {
    total: number,
    first_inspection: number,
    each_additional_selection: number,
}
type InspectionCheckoutQueryArgs = {
    inspection_companies: number[]
}

type InspectionPriceQueryResult = {
    single_inspection: number,
    bundle_inspection: number,
}

type adminInspectionsArchivePaginatedResult = {
    count: number,
    list: Inspection[]
}

type adminInspectionsArchiveListQueryResult = Inspection[]

type adminInspectionsArchiveQueryArgs = {
    ordering?: "date_modified" | "-date_modified",
    offset?: number,
    limit?: number,

    status?: "reviewed" | "rejected",
    status__in?: ("reviewed" | "rejected")[],
}

type adminActiveInspectionsPaginatedResult = {
    count: number,
    list: Inspection[]
}


type adminActiveInspectionsListQueryResult = Inspection[]

type adminActiveInspectionsQueryArgs = {
    ordering?: "date_modified" | "-date_modified",
    offset?: number,
    limit?: number,

    status?: "reviewed" | "rejected",
    status__in?: ("reviewed" | "rejected")[],
}

type adminActiveInspectionDetailsQueryArgs = number
type adminActiveInspectionDetailsQueryResult = Inspection

type adminArchiveInspectionDetailsQueryArgs = number
type adminArchiveInspectionDetailsQueryResult = Inspection

type adminStartInspectionQueryArgs = {
    id: number,
}
type adminStartInspectionQueryResult = Inspection

type adminAcceptInspectionRequestQueryArgs = {
    id: number,
}
type adminAcceptInspectionRequestQueryResult = Inspection

type adminRejectInspectionRequestQueryArgs = {
    id: number,
    incorrect_fields: string,
    rejection_reason: string,
}
type adminRejectInspectionRequestQueryResult = Inspection

type adminReturnInspectionArchivedRequestQueryArgs = {
    id: number,
}
type adminReturnInspectionArchivedRequestQueryResult = Inspection

type adminLoadInspectionCertificateQueryArgs = {
    file: File,

    inspection: number,
    company: number,
} | {
    fields_data: {[name: string]: string},
    inspection: number,
    company: number,
}
type adminLoadInspectionCertificateQueryResult = Inspection

export const userInspectionApi = api.enhanceEndpoints({addTagTypes: ['Inspection']}).injectEndpoints({
    endpoints: builder => ({
        userInspectionsList: builder.query<Inspection[], void | InspectionsListFilters>({
            query: (args) => ({
                url: "inspections/list",
                method: "GET",
                params: args,
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [
                        ...(result as Inspection[])?.map(({id}, idx) => ({type: 'Inspection' as const, id}))
                    ]
                }
            }
        }),
        inspectionDetails: builder.query<inspectionDetailsQueryResult, inspectionDetailsQueryArgs>({
            query: ({id}) => ({
                url: `inspections/list/${id}/`,
                method: "GET",
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        userNewInspection: builder.mutation<UserNewInspectionQueryResult, UserNewInspectionQueryArgs>({
            query: (args) => ({
                url: "inspections/new/",
                method: "POST",
                body: args,
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection', id: result.inspection.id}]
                }
            }
        }),
        userReInspection: builder.mutation<UserReInspectionQueryResult, UserReInspectionQueryArgs>({
            query: ({id, ...args}) => ({
                url: `inspections/re-inspect/${id}/`,
                method: "PATCH",
                body: args,
            }),
            invalidatesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection', id: result.inspection.id}]
                }
            }
        }),
        inspectionCheckout: builder.query<InspectionCheckoutQueryResult, InspectionCheckoutQueryArgs>({
            query: (args) => ({
                url: 'inspections/checkout_info',
                method: "GET",
                params: args,
            })
        }),
        inspectionPrice: builder.query<InspectionPriceQueryResult, void>({
            query: () => ({
                url: 'inspections/price',
                method: "GET",
            })
        }),
        adminInspectionsArchive: builder.query<adminInspectionsArchivePaginatedResult & adminInspectionsArchiveListQueryResult, void | adminInspectionsArchiveQueryArgs>({
            query: (args) => ({
                url: "inspections/archive",
                method: "GET",
                params: args,
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    if (Object.keys(result).includes('list')) {
                        return [
                            ...(result as adminInspectionsArchivePaginatedResult)?.list.map(({id}, idx) => ({
                                type: 'Inspection' as const,
                                id
                            }))
                        ]
                    }
                    return [
                        ...(result as adminInspectionsArchiveListQueryResult)?.map(({id}, idx) => ({
                            type: 'Inspection' as const,
                            id
                        }))
                    ]
                }
            }
        }),
        adminActiveInspections: builder.query<adminActiveInspectionsPaginatedResult & adminActiveInspectionsListQueryResult, void | adminActiveInspectionsQueryArgs>({
            query: (args) => ({
                url: "inspections/active",
                method: "GET",
                params: args,
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    if (Object.keys(result).includes('list')) {
                        return [
                            ...(result as adminActiveInspectionsPaginatedResult)?.list.map(({id}, idx) => ({
                                type: 'Inspection' as const,
                                id
                            }))
                        ]
                    }
                    return [
                        ...(result as adminActiveInspectionsListQueryResult)?.map(({id}, idx) => ({
                            type: 'Inspection' as const,
                            id
                        }))
                    ]
                }
            }
        }),
        adminActiveInspectionDetails: builder.query<adminActiveInspectionDetailsQueryResult, adminActiveInspectionDetailsQueryArgs>({
            query: (id) => ({
                url: `inspections/active/${id}`,
                method: "GET",
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        adminArchiveInspectionDetails: builder.query<adminArchiveInspectionDetailsQueryResult, adminArchiveInspectionDetailsQueryArgs>({
            query: (id) => ({
                url: `inspections/archive/${id}`,
                method: "GET",
            }),
            providesTags: (result) => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        adminStartInspection: builder.mutation<adminStartInspectionQueryResult, adminStartInspectionQueryArgs>({
            query: ({id}) => ({
                url: `inspections/start/${id}`,
                method: "POST",
            }),
            invalidatesTags: result => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        adminAcceptInspectionRequest: builder.mutation<adminAcceptInspectionRequestQueryResult, adminAcceptInspectionRequestQueryArgs>({
            query: ({id}) => ({
                url: `inspections/accept/${id}`,
                method: "POST",
            }),
            invalidatesTags: result => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        adminRejectInspectionRequest: builder.mutation<adminRejectInspectionRequestQueryResult, adminRejectInspectionRequestQueryArgs>({
            query: ({id, incorrect_fields, rejection_reason}) => ({
                url: `inspections/reject/${id}`,
                method: "POST",
                body: {
                    incorrect_fields: incorrect_fields,
                    rejection_reason: rejection_reason,
                }
            }),
            invalidatesTags: result => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        adminReturnInspectionArchivedRequest: builder.mutation<adminReturnInspectionArchivedRequestQueryResult, adminReturnInspectionArchivedRequestQueryArgs>({
            query: ({id}) => ({
                url: `inspections/return/${id}`,
                method: "POST",
            }),
            invalidatesTags: result => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        }),
        adminLoadInspectionCertificate: builder.mutation<adminLoadInspectionCertificateQueryResult, adminLoadInspectionCertificateQueryArgs>({
            query: (data) => ({
                url: 'inspections/certificate/load/',
                method: "POST",
                body: data,
            }),
            invalidatesTags: result => {
                if (!result) {
                    return ['Inspection']
                } else {
                    return [{type: 'Inspection' as const, id: result.id}]
                }
            }
        })
    })
})

export const {
    useUserInspectionsListQuery,
    useUserNewInspectionMutation,
    useInspectionDetailsQuery,
    useUserReInspectionMutation,
    useInspectionCheckoutQuery,
    useInspectionPriceQuery,
    useAdminActiveInspectionsQuery,
    useAdminInspectionsArchiveQuery,
    useAdminActiveInspectionDetailsQuery,
    useAdminArchiveInspectionDetailsQuery,
    useAdminStartInspectionMutation,
    useAdminAcceptInspectionRequestMutation,
    useAdminRejectInspectionRequestMutation,
    useAdminReturnInspectionArchivedRequestMutation,
    useAdminLoadInspectionCertificateMutation,
} = userInspectionApi
