import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Stepper, styled} from "@mui/material";

const stepStyle = {
    "& .MuiStep-root": {
        "& .MuiSvgIcon-root": {
            fill: "#c4c4c4",
        },

        "& .MuiStepConnector-line": {
            borderColor: "#f5f5f5"
        }
    },
    "& .Mui-completed": {
        "& .MuiSvgIcon-root": {
            fill: "#70dfdf",
        },
        "& .MuiStepConnector-line": {
            borderColor: "#70DFDF"
        }
    }
}

export const InspectionFormStepper = styled(Stepper)(stepStyle)