import {Navigate, Route, Routes} from "react-router-dom";
import {UserLayout} from "@layouts/UserLayout/UserLayout";
import {Home} from "@pages/user/Home/Home";
import {SignUp} from "@pages/user/SignUp/SignUp";
import {SignIn} from "@pages/user/SignIn/SignIn";
import {PermittedComponent} from "@helpers/components/PermittedComponent/PermittedComponent";
import {ContactUs} from "@pages/user/ContactUs/ContactUs";
import {FinishSignUp} from "@pages/user/FinishSignUp/FinishSignUp";

export const GuestRoutes = () => (
    <PermittedComponent allowedRoles={[null]}>
        <Routes>
            <Route element={<UserLayout/>}>
                <Route index element={<Home/>}/>
                <Route path="sign_up" element={<SignUp/>}/>
                <Route path="sign_up/finish" element={<FinishSignUp/>}/>
                <Route path="sign_in" element={<SignIn/>}/>
                <Route path="contact-us" element={<ContactUs/>}/>
            </Route>
            <Route element={<Navigate to="/" replace/>} path="/*"/>
        </Routes>
    </PermittedComponent>
)