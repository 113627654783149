import {FunctionComponent} from "react"

import LogoutIcon from "@assets/icons/home/LogoutIcon.svg"
import DataProcessingIcon from "@assets/icons/home/DataProcessingIcon.svg"
import PhotoSelectionIcon from "@assets/icons/home/PhotoSelectionIcon.svg"
import PaymentIcon from "@assets/icons/home/PaymentIcon.svg"
import ThinArrowIcon from "@assets/icons/home/ThinArrowIcon.svg"
import ThickArrowIcon from "@assets/icons/home/ThickArrowIcon.svg"

import {Image} from "@components/common/images/Image/Image";

import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

export const TimingSection: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.section}>
            <div className={styles.desktop}>
                <div className={styles.card}>
                    <Image src={LogoutIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                    <h2 className={styles.timing}>
                        {t("user:Home.TimingSection.register.timing")}
                    </h2>
                    <p className={styles.description}>
                        {t("user:Home.TimingSection.register.description")}
                    </p>
                </div>
                <div className={styles.arrow}>
                    <Image src={ThinArrowIcon} alt="" skeletonHeight={6}/>
                </div>
                <div className={styles.card}>
                    <Image src={DataProcessingIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                    <h2 className={styles.timing}>
                        {t("user:Home.TimingSection.stateSelection.timing")}
                    </h2>
                    <p className={styles.description}>
                        {t("user:Home.TimingSection.stateSelection.description")}
                    </p>
                </div>
                <div className={styles.arrow}>
                    <Image src={ThinArrowIcon} alt="" skeletonHeight={6}/>
                </div>
                <div className={styles.card}>
                    <Image src={PhotoSelectionIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                    <h2 className={styles.timing}>
                        {t("user:Home.TimingSection.photosSelection.timing")}
                    </h2>
                    <p className={styles.description}>
                        {t("user:Home.TimingSection.photosSelection.description")}
                    </p>
                </div>
                <div className={styles.arrow}>
                    <Image src={ThinArrowIcon} alt="" skeletonHeight={6}/>
                </div>
                <div className={styles.card}>
                    <Image src={PaymentIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                    <h2 className={styles.timing}>
                        {t("user:Home.TimingSection.payment.timing")}
                    </h2>
                    <p className={styles.description}>
                        {t("user:Home.TimingSection.payment.description")}
                    </p>
                </div>
            </div>
            <div className={styles.mobile}>
                <div className={styles.straightRow}>
                    <div className={styles.card}>
                        <Image src={LogoutIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                        <h2 className={styles.timing}>
                            {t("user:Home.TimingSection.register.timing")}
                        </h2>
                        <p className={styles.description}>
                            {t("user:Home.TimingSection.register.description")}
                        </p>
                    </div>
                    <div className={styles.arrow}>
                        <Image src={ThickArrowIcon} alt="" skeletonWidth={28} skeletonHeight={26}/>
                    </div>
                    <div className={styles.card}>
                        <Image src={DataProcessingIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                        <h2 className={styles.timing}>
                            {t("user:Home.TimingSection.stateSelection.timing")}
                        </h2>
                        <p className={styles.description}>
                            {t("user:Home.TimingSection.stateSelection.description")}
                        </p>
                    </div>
                </div>
                <div className={styles.horizontalArrowContainer}>
                    <div/>
                    <div className={styles.hidden}>
                        <Image src={ThickArrowIcon} alt="" skeletonWidth={28} skeletonHeight={26}/>
                    </div>
                    <div className={styles.arrow}>
                        <Image src={ThickArrowIcon} alt="" skeletonWidth={28} skeletonHeight={26}/>
                    </div>
                </div>
                <div className={styles.backwardRow}>
                    <div className={styles.card}>
                        <Image src={PhotoSelectionIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                        <h2 className={styles.timing}>
                            {t("user:Home.TimingSection.photosSelection.timing")}
                        </h2>
                        <p className={styles.description}>
                            {t("user:Home.TimingSection.photosSelection.description")}
                        </p>
                    </div>
                    <div className={styles.arrow}>
                        <Image src={ThickArrowIcon} alt="" skeletonWidth={28} skeletonHeight={26}/>
                    </div>
                    <div className={styles.card}>
                        <Image src={PaymentIcon} alt="" className={styles.icon} skeletonWidth={60} skeletonHeight={61}/>
                        <h2 className={styles.timing}>
                            {t("user:Home.TimingSection.payment.timing")}
                        </h2>
                        <p className={styles.description}>
                            {t("user:Home.TimingSection.payment.description")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}