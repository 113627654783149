import {ChangeEvent, FunctionComponent, useState} from "react"

import styles from "./styles.module.css"
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {WhiteSelectInput} from "@components/common/inputs/WhiteSelectInput/WhiteSelectInput";
import {useStatesListQuery} from "@features/states/api";
import {Form, Formik, FormikHelpers} from "formik";
import {WhiteSelectCompanyInput} from "@components/common/inputs/WhiteSelectCompanyInput/WhiteSelectCompanyInput";
import {useCompaniesListQuery} from "@features/companies/api";
import {useTranslation} from "react-i18next";
import {GreenRoundedButton} from "@components/common/buttons/GreenRoundedButton/GreenRoundedButton";
import {getFormData, getFormValues, setFormData} from "@helpers/multiStepForm";
import {NEW_INSPECTION_FORM_DATA_KEY} from "@helpers/constants/inspection";
import {useLocation, useNavigate} from "react-router-dom";
import {generalDataFormValidationSchema} from "@helpers/validationSchemas/new-inspection";
import {InspectionCostCard} from "@components/user/InspectionCostCard/InspectionCostCard";
import {useInspectionPriceQuery} from "@features/inspection/api";
import {GreenRadioButton} from "@components/common/radioButtons/GreenRadioButton/GreenRadioButton";

interface GeneralDataProps {
    title?: string,
    description?: string,
}

interface Values {
    license_plate_number: string,
    applicant_drivers_license: string,
    inspection_state?: number,
    inspection_companies: number[],
    has_renewed_tires?: boolean | null,
    has_renewed_battery?: boolean | null,
    is_battery_voltage_high_enough?: boolean | null,
}

export const GeneralData: FunctionComponent<GeneralDataProps> = () => {
    const initialValues: Values = {
        license_plate_number: "",
        applicant_drivers_license: "",
        inspection_state: undefined,
        inspection_companies: [],
        ...getFormValues(NEW_INSPECTION_FORM_DATA_KEY, 'license_plate_number', 'inspection_state', 'inspection_companies', 'applicant_drivers_license'),
    }

    const navigate = useNavigate()
    const location = useLocation()

    const {t} = useTranslation()

    const [inspectionState, setInspectionState] = useState<number | undefined>(initialValues.inspection_state)

    const {data: inspection_states} = useStatesListQuery()
    const {data: inspection_prices} = useInspectionPriceQuery()
    const {data: companies} = useCompaniesListQuery({
        states__id: inspectionState
    }, {
        refetchOnMountOrArgChange: true
    })

    const onInspectionStateChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setInspectionState(parseInt(event.target.value))
    }

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setFormData(NEW_INSPECTION_FORM_DATA_KEY, {
            ...getFormData(NEW_INSPECTION_FORM_DATA_KEY),
            ...values
        })

        navigate(location, {
            ...location,
            state: {
                activeStep: 1,
            },
        })
        setSubmitting(false)
    }

    return (
        <div className={styles.step}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validationSchema={generalDataFormValidationSchema}>
                {({
                      isSubmitting,
                      errors,
                      values,
                  }) => (
                    <Form className={styles.form}>
                        <WhiteInput name="license_plate_number"
                                    label={t("user:NewInspection.GeneralData.LicensePlateInput.label")}/>
                        <WhiteInput name="applicant_drivers_license" label={t("user:NewInspection.GeneralData.ApplicantDriversLicense.label")}/>
                        <WhiteSelectInput name="inspection_state"
                                          label={t("user:NewInspection.GeneralData.InspectionStateInput.label")}
                                          onChange={onInspectionStateChange} noValueOption>
                            {inspection_states?.map((obj, idx) => <option value={obj.id} key={idx}>{obj.name}</option>)}
                        </WhiteSelectInput>
                        {
                            !!inspectionState &&
                            <WhiteSelectCompanyInput
                                name="inspection_companies"
                                label={t("user:NewInspection.GeneralData.InspectionCompanySelect.label")}
                                companies={companies}/>
                        }
                        {
                            values.inspection_companies.includes(3) &&
                            <>
                                <div>
                                    <p className={styles.label}>
                                        {t("user:NewInspection.GeneralData.HasRenewedTires.label")}
                                    </p>
                                    <div className={styles.fieldset}>
                                        <GreenRadioButton label="Yes" name="has_renewed_tires" value={1}/>
                                        <GreenRadioButton label="No" name="has_renewed_tires" value={0}/>
                                    </div>
                                </div>
                                <div>
                                    <p className={styles.label}>
                                        {t("user:NewInspection.GeneralData.HasRenewedBattery.label")}
                                    </p>
                                    <div className={styles.fieldset}>
                                        <GreenRadioButton label="Yes" name="has_renewed_battery" value={1}/>
                                        <GreenRadioButton label="No" name="has_renewed_battery" value={0}/>
                                    </div>
                                </div>
                                {
                                    values.has_renewed_battery !== undefined && !values.has_renewed_battery &&
                                    <div>
                                        <p className={styles.label}>
                                            {t("user:NewInspection.GeneralData.IsBatteryVoltageHighEnough.label")}
                                        </p>
                                        <div className={styles.fieldset}>
                                            <GreenRadioButton label="Yes" name="is_battery_voltage_high_enough" value={1}/>
                                            <GreenRadioButton label="No" name="is_battery_voltage_high_enough" value={0}/>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        <InspectionCostCard title={t("user:NewInspection.GeneralData.SingleInspectionCostCard.Label")}
                                            description={t("user:NewInspection.GeneralData.SingleInspectionCostCard.Description")}
                                            price={inspection_prices?.single_inspection}/>
                        <InspectionCostCard
                            title={t("user:NewInspection.GeneralData.EachAdditionalInspectionCostCard.Label")}
                            description={t("user:NewInspection.GeneralData.EachAdditionalInspectionCostCard.Description")}
                            price={inspection_prices?.bundle_inspection}/>
                        <div className={styles.submitButton}>
                            <GreenRoundedButton type="submit"
                                                disabled={isSubmitting || !!Object.values(errors).length}>{t("common:Inspection.NextStepButton")}</GreenRoundedButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}