import {FunctionComponent} from "react"

import styles from "./styles.module.css"
import {Company, Inspection} from "@appTypes/inspection";
import {WhiteInput} from "@components/common/inputs/WhiteInput/WhiteInput";
import {Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {Link} from "react-router-dom";
import {useAdminLoadInspectionCertificateMutation} from "@features/inspection/api";
import {convertApiFormErrors, isApiFormResponse} from "@helpers/errors";
import {GreenButton} from "@components/common/buttons/GreenButton/GreenButton";

import DownloadIcon from "@mui/icons-material/Download";
import {LightBox} from "@helpers/components/LightBox/LightBox";

interface CertificateConstructorProps {
    inspection: Inspection,
    company: Company,
}

interface Values {
    [name: string]: string,
}

export const CertificateConstructor: FunctionComponent<CertificateConstructorProps> = ({inspection, company}) => {
    const initialValues: Values = Object.fromEntries(Object.entries((inspection.certificates
        .find((certificate) => certificate.company.id === company.id)?.fields_data || Object.fromEntries(inspection.required_fields[company.id].map((fieldName) => [fieldName, '']) || []))).map(([field, value]) => {

        if (field.toLowerCase().includes('has_renewed_battery')) {
            return ['invisible__' + field, +(inspection?.has_renewed_battery || value)]
            // return [field, inspection?.has_renewed_battery && !!+inspection.has_renewed_battery ? 'Yes' : 'No']
        }
        if (field.toLowerCase().includes('is_battery_voltage_high_enough')) {
            return ['invisible__' + field, +(inspection?.is_battery_voltage_high_enough || value)]
            // return [field, inspection?.is_battery_voltage_high_enough && !!+inspection.is_battery_voltage_high_enough ? 'Yes' : 'No']
        }

        if (value) {
            return [field, value]
        }

        if (field.toLowerCase().includes('driver') || field.toLowerCase().includes('host')) {
            if (field.toLowerCase().includes('name')) {
                return [field, `${inspection.user.last_name} ${inspection.user.first_name}`]
            }
            if (field.toLowerCase().includes('phone')) {
                return [field, inspection.user.phone_number]
            }
            if (field.toLowerCase().includes('email') || field.toLowerCase().includes('e-mail')) {
                return [field, inspection.user.email]
            }
        }
        if (field.toLowerCase().includes('license') && field.toLowerCase().includes('plate')) {
            return [field, inspection.license_plate_number]
        }

        if (field.toLowerCase().includes('inspection') && field.toLowerCase().includes('date')) {
            if (field.toLowerCase().includes('dd')) {
                return [field, (new Date()).getDate().toString().padStart(2, '0')]
            }
            if (field.toLowerCase().includes('mm')) {
                return [field, (new Date()).getMonth().toString().padStart(2, '0')]
            }
            if (field.toLowerCase().includes('yyyy')) {
                return [field, (new Date()).getFullYear().toString()]
            }
            return [field, (new Date()).toLocaleDateString('en-US')]
        }

        return [field, ""]
    }))

    const [loadCertificate, {isLoading}] = useAdminLoadInspectionCertificateMutation()

    const onSubmit = async (values: Values, {setSubmitting, setErrors}: FormikHelpers<Values>) => {
        setSubmitting(true)

        const clearValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key.replace('invisible__', ''), value]))

        try {
            await loadCertificate({fields_data: {...clearValues}, company: company.id, inspection: inspection.id}).unwrap()
        } catch (e) {
            if (isApiFormResponse(e)) {
                const errors = convertApiFormErrors(e)
                setErrors(errors.errors as unknown as FormikErrors<Values>)
            }
        } finally {
            setSubmitting(false)
        }
    }

    const validate = (values: Values) => {
        const errors: Values = {}

        Object.keys(values).forEach((key) => {
            if (key.startsWith('invisible__')) {
                return
            }
            if (!values[key]) {
                errors[key] = 'required field'
            }
        })

        console.log(errors)

        return errors
    }

    const certificateFile = inspection.certificates.find((certificate) => certificate.company.id === company.id)?.file || null

    return (
        <div className={styles.contents}>
            <div className={styles.registrationCardPreview}>
                <div>
                    <LightBox>
                        <img src={inspection.registration_card.file} alt=""/>
                    </LightBox>
                </div>
            </div>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {({isValid, isSubmitting}) => (
                    <Form className={styles.form}>
                        <div className={styles.fields}>
                            {Object.entries(initialValues).map(([field, value], idx) => (
                                !field.startsWith('invisible__') && <WhiteInput label={field.replaceAll('_', ' ')} name={field} key={idx} defaultValue={value}/>
                            ))}
                        </div>
                        <div className={styles.submitButton}>
                            <GreenButton type="submit" disabled={!isValid || isLoading || isSubmitting}>
                                Load
                            </GreenButton>
                            {
                                certificateFile &&
                                <Link to={certificateFile} target="_blank" download className={styles.link}>
                                    {company.name} <DownloadIcon fontSize="inherit"/>
                                </Link>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
